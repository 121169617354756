import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { getCategory, specialsTheme, getAllCategoryIds, getAllSeries } from '../logic/categories';
import AnimatedBrain from '../components/AnimatedBrain';

// This logic is duplicated between the App and the Gatsby static web site

const Category = ({ categoryId }) => {
  const category = getCategory(categoryId);

  const { theme } = category;

  return (
    <Link to={`/browse-programs/by-category/${encodeURIComponent(categoryId)}`} style={{ display: 'flex', padding: '10px 20px', borderRadius: 5, flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', backgroundColor: theme.bg, marginBottom: 10, marginTop: 40, color: theme.chevron }}>
      {category.title}

      <AnimatedBrain categoryId={categoryId} size={80} style={{ flexShrink: 0, marginTop: -70 }} />
    </Link>
  );
};

const Series = ({ series }) => (
  <Link to={`/browse-programs/by-series/${encodeURIComponent(series.key)}`} style={{ display: 'flex', padding: '10px 20px', borderRadius: 5, flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', backgroundColor: 'rgba(0,0,0,0.04)', marginBottom: 20 }}>
    <div>
      <div style={{ fontSize: '110%', color: '#444', fontWeight: 'bold' }}>{series.title}</div>
      <div style={{ color: '#666' }}>{series.subtitle}</div>
    </div>

    <AnimatedBrain seriesId={series.key} size={120} style={{ flexShrink: 0 }} />
  </Link>
);

export default () => (
  <Layout showCart>
    <SEO title="Programs" />

    <div>
      <div className="section bg-gray-4">
        <div className="container">
          <div className="row row-split-content">
            <div className="content-width-small">
              <h6 className="subheading text-primary-1">A program for everyone</h6>
              <div className="handwriting-wrapper">
                <h3 className="h1"><strong>Changing your life has never been easier<br /></strong></h3>
              </div>
              <div>Are there things in your life that you’d like to change? Are there things you see in yourself that you’d like to improve? We’ve leveraged our more than 45 years of experience with hundreds of thousands of people to build a vast library of prescriptive programs that address your needs and will help you change your life!</div>
            </div>
            <div className="card bg-white shadow-small">
              <div className="card-body card-body-large">
                <ul className="icon-list w-list-unstyled">
                  <li className="icon-list-item">
                    <div className="circle-small margin-right"><img src="/fonts/5dc99dd0c36766296859375f_icon-check-small.svg" alt="" /></div>
                    <div>Over 120 prescriptive titles to address your needs</div>
                  </li>
                  <li className="icon-list-item">
                    <div className="circle-small margin-right"><img src="/fonts/5dc99dd0c36766296859375f_icon-check-small.svg" alt="" /></div>
                    <div>Programs for guided meditation or while driving, exercising, working or even sleeping</div>
                  </li>
                  <li className="icon-list-item">
                    <div className="circle-small margin-right"><img src="/fonts/5dc99dd0c36766296859375f_icon-check-small.svg" alt="" /></div>
                    <div>Subscriptions offer unlimited, on-demand access to the entire library any time, anywhere</div>
                  </li>
                  <li className="icon-list-item icon-list-item-last">
                    <div className="circle-small margin-right"><img src="/fonts/5dc99dd0c36766296859375f_icon-check-small.svg" alt="" /></div>
                    <div>Or purchase perpetual access to individual titles here or in-app</div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div style={{ margin: '70px 50px' }}>
        <div style={{ margin: 'auto', maxWidth: 600 }}>
          <p style={{ fontSize: 20 }}>The WiseGuide App is your access to the greatest self-improvement tools ever created.</p>
          <ul>
            <li>
              <b>Subscriptions:</b>&nbsp;&nbsp;&nbsp;A monthly or annual subscription will provide you with unlimited access to the entire library of programs.<br/ >
              <Link to="/pricing">Click here to subscribe.</Link>
            </li>
            <li><b>Purchases:</b>&nbsp;&nbsp;&nbsp;If you prefer to have perpetual access to specific titles within the app with no recurring subscription fee, you can purchase those titles here on this site (browse the titles below).  Then download the free app to access your purchased titles. You can buy more titles or upgrade to a subscription at any time here at this site or within the app.</li>
          </ul>

          <h2 style={{ marginTop: 50 }}>Browse by Category</h2>

          {getAllCategoryIds().map((categoryId) => (
            <Category key={categoryId} categoryId={categoryId} />
          ))}

          <Link to="/browse-programs/bundles" style={{ display: 'block', padding: '10px 20px', borderRadius: 5, flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', backgroundColor: specialsTheme.bg, marginBottom: 10, marginTop: 40, color: '#fff' }}>
            Special Bundles
          </Link>

          <h2 style={{ marginTop: 50 }}>Browse by Series</h2>

          {getAllSeries().map((series) => (
            <Series key={series.key} series={series} />
          ))}

          <a href="https://checkout.thewiseguideapp.com/all-products/" style={{ fontSize: 36, marginTop: 50, display: 'block' }}>
            Browse All Titles
          </a>
        </div>
      </div>
    </div>
  </Layout>
);
